/* ////////////// FONT START /////////////////// */

.HomeFontRALEWAY {
    font-family: 'Raleway', sans-serif;
}

.HomeFontRALEWAYLIGHT {
    font-family: 'Raleway', sans-serif;
}

.HomeFontEXCULSIVEPUNERATE {
    font-family: 'Shadows Into Light';
}

.FontFamilyMontSerret {
    font-family: 'Montserrat', sans-serif;
}

.YESEVA {
    font-family: 'Yeseva One', cursive;
}

/* /////  FONT END   ////// */

.BOOKINGHEADER {
    color: #fff;
}

.BOOKINGHEADER:hover {
    color: #212121;
}


.FONTSIZE {
    font-size: 17px;
}

.SILDERHEIGHT {
    height: 580px;
}

.ALLBG {
    height: 550px;
}


.HOMEFIRSTCONTENT {
    width: 33%;
    top: 40%;
    left: 30%;
}

.HOMEFIRSTCONTENTH2 {
    font-size: 35px;
    line-height: 1.5;
}

.FIRSTCONTENT {
    width: 95%;
}

.CARIMG {
    height: 188px;
}

.BOOKNOW {
    color: #fff;
    font-size: 12px;
    background-color: black;
    border: 1px solid black;
    height: 40px;
    letter-spacing: 2px;
}

.BOOKNOW:hover {
    color: black;
    background-color: transparent;
    border: 1px solid black;
    height: 40px;
    letter-spacing: 1px;
}

.HOTELORROOMSBOOKNOW {
    color: #633;
    font-size: 13px;
    background-color: transparent;
    border: 1px solid black;
    height: 40px;
}

.HOTELORROOMSBOOKNOW:hover {
    color: #fff;
    background-color: black;
    border: 1px solid black;
    height: 40px;
}


.HEDERBOOKNOW {
    color: #282828;
    font-size: 13px;
    background-color: #ffb612;
    border: 1px solid #ffb612;
    height: 38px;
    width: 110px;
}

.HEDERBOOKNOW:hover {
    color: #fff;
    background-color: black;
    border: 1px solid black;
    height: 38px;
    width: 110px;
}


.btnStyle {
    width: 100%;
    padding: 10px;
    color: #555;
    border: 1px solid #ddd;
    background-color: #fff;
    font-weight: 400;
}

.newStyle {
    width: 100%;
    padding: 10px;
    color: #ffeb3b;
    border: 1px solid #ddd;
    background-color: #1d292e;
    font-weight: 400;
}


.FORMWIDTH {
    width: 85%;
}

@media only screen and (max-width: 1200px) {

    .FONTSIZE {
        font-size: 11px;
    }

    .SILDERHEIGHT {
        height: 400px;
    }

    .ALLBG {
        height: 400px;
    }

    .HOTELORROOMSBOOKNOW {
        height: 48px;
        width: 100%;
    }

    .HEDERBOOKNOW {
        height: 48px;
        width: 100%;
    }

    .FORMWIDTH {
        width: 100%;
    }

    .HOMEFIRSTCONTENT {
        width: 95%;
        left: 50%;
    }

    .HOMEFIRSTCONTENTH2 {
        font-size: 30px;
    }

    .FIRSTCONTENT {
        width: 100%;
    }

    .CARIMG {
        height: 100%;
    }
}