.emailHover1 {
    color: #EA6025;
    text-decoration: underline;
}

.emailHover1:hover {
    text-decoration: underline;
    color: #777;
}


.IconHoverEffect1 {
    background-color: #A2D25E;
    border-radius: 50%;

}

.IconHoverEffect1:hover {
    cursor: pointer;
}




.IconHoverEffect2 {
    background-color: #5CD0BC;
    border-radius: 50%;

}

.IconHoverEffect2:hover {
    cursor: pointer;
}


.IconHoverEffect3 {
    background-color: #f05c5b;
    border-radius: 50%;

}

.IconHoverEffect3:hover {
    cursor: pointer;
}

.ColHover {
    transition: transform 1.1s;
}

.ColHover:hover {
    transform: scale(1.2);
    overflow: hidden;
}


.SendButton {
    color: #fff;
    font-size: 13px;
    background-color: #282828;
    border: 1px solid #282828;
    height: 44px;
}

.SendButton:hover {
    color: #282828;
    height: 44px;
    background-color: #fff;
    border: 1px solid #282828;
}