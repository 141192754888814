

.FOOTERHOVER{
    color: #fff;
}

.FOOTERHOVER:hover{
    color: #F26722;
    cursor: pointer;
}

.FontFamilyMontSerret {
    font-family: 'Montserrat', sans-serif;
}