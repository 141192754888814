

.FontMontSerret{
    font-family: 'Montserrat', sans-serif;
    color: #fff;  
}


.HEADERHOVEREFFECT{
    color: #fff;
    font-size: 14px;
    text-decoration: none;
}

.HEADERHOVEREFFECT:hover{
    color: #fff;
    font-size: 14px;
    text-decoration: underline;
}