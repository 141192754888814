
.FORMWIDTH{
    width: 70%;
}

@media only screen and (max-width: 1200px) {
    
    .FORMWIDTH{
        width: 100%;
    }

}